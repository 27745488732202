import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { linkResolver } from '../utils/linkResolver'
import Moment from "moment"
import Button from "../components/button"

export const query = graphql`
query blogQuery {
    prismic {
      allPosts(sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            post_body
            title
            _meta {
              firstPublicationDate
              uid
              type
            }
            author {
              ... on PRISMIC_Staff {
                first_name
                _meta {
                    type
                  uid
                }
              }
            }
            guest_author
          }
        }
      }
    }
  }
`
const firstParagraph = (post => {
  let firstTextSlice = post.text;
  if (firstTextSlice != null) {
    const textLimit = 300
    let text = firstTextSlice
    let limitedText = text.substring(0, textLimit)
    if (text.length > textLimit) {
      return (
        <p>{limitedText.substring(0, limitedText.lastIndexOf(' ')) + '...'}</p>
      );
    } else {
      return <p>{text}</p>;
    }
  } else {
    return null;
  }
})

export const blogPage = ({ data }) => (
  <div className="body">
    <Layout>
      <SEO title="Blog" />
      <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
        <section className="section">
          <div className="page-heading-parent">
            <h1>Updates—Pubdates.</h1>
            <div className="line grey"></div>
          </div>
          <div className="w-layout-grid preview-grid blog">
            {data.prismic.allPosts.edges.map(({ node: post }, i) => (
              <div key={i} className="preview">
                <Link to={linkResolver(post._meta)}>
                  <div className="text-link w-inline-block">
                    <h2 className="heading">{post.title[0].text}</h2>
                  </div>
                </Link>
                {firstParagraph(post.post_body[0])}
                <div className="post-details">
                  {
                    post.author != null ?
                      <div><Link to={linkResolver(post.author._meta)} className="text-link"><strong>{post.author.first_name[0].text}</strong></Link> on {Moment(post._meta.firstPublicationDate).format("LL")}</div>
                      :
                      post.guest_author != null ?
                        <div><strong>{post.guest_author[0].text}</strong> on {Moment(post._meta.firstPublicationDate).format("LL")}</div>
                        :
                        <div><strong>Anonymous</strong> on {Moment(post._meta.firstPublicationDate).format("LL")}</div>
                  }
                </div>
              </div>
            ))}
          </div>
          <div className="support-block">
            <h2>Student-run and student-led, we rely on your support.</h2>
            <div className="button-parent page">
              <Button name="Support Us" to="/help-out/" />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  </div>
)
export default blogPage